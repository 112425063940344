@import '@/shared/styles/crema.less';
@import '@/shared/styles/vendors/index.css';

:root {
  --bg-color: #0f4171;
}

.ant-btn-icon-only.ant-btn-sm {
  padding: 1.5px 0 !important;
}

@media screen and (min-width: 1280px) {
  .framedLayout {
    background-color: var(--bg-color) !important;
  }
}

@primary-color: #0A8FDC;